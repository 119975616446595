
import { defineComponent } from 'vue';
import { AppWrapper } from '@/components';

export default defineComponent({
  name: 'BOK',
  components: {
    AppWrapper
  },
  data () {
    return {
    };
  }
});
